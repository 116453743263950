.place__editor .codex-editor__redactor {
    padding-bottom: 30px!important;
}

/* On Chrome */
.place__editor .codex-editor__redactor::-webkit-scrollbar {
    display: none;
}
/* For Firefox and IE */
.place__editor .codex-editor__redactor {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.place__editor.identor .ce-paragraph {
    text-indent: 40px;
}