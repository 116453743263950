.sidebar-overlay {
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: #00000099;
}

.sidebar-overlay.is-active {
    display: block;
    z-index: 10;
}